<template>
<v-navigation-drawer width="1280" right v-model="show" app temporary :permanent="mobileBreakpoint == true">
    <v-col class="px-11 pb-11">
        <v-row>
            <v-col class="pb-0" style="z-index: 99999" align="right" @click="closeDemandSlider">
                <v-icon class="primaryColorOnHover xClass">
                    mdi-close
                </v-icon>
            </v-col>
        </v-row>
        <v-row :class="smAndDownBreakpoint == true ? 'flex-column' : ''">
            <v-col class="flex-grow-0" @click=" openContact(demandAvatarObj.id)">
                <DemandAvatar :data="demandAvatarObj.data" :isImg="demandAvatarObj.isImg" :showName="true" :nameContent="demandAvatarObj.nameContent" fontSizeName="23px; color: #263573; padding-top:14px;" :showSubContent="true" :subContent="demandAvatarObj.subContent" backgroundColor="#77BD86" :size="demandAvatarObj.size" fontSizeImg="25px" subContentClass="textDecorationBold primaryColor mt-n1" :iconObj="iconObj" :ratingPosition="true" :ratingData="demandAvatarObj.ratingData" :showRating="demandAvatarObj.showRating" rowClass="flex-nowrap" :starSize="20" ratingFieldClass="mt-n1 pl-0 ml-n1" :nameTitleContent="demandAvatarObj.nameTitleContent" />
            </v-col>
            <v-col>
                <v-row class="justify-end mt-0 flex-grow-0" :class="mobileBreakpoint == true ? 'flex-column' : ''">
                    <v-col class="flex-grow-0">
                        <v-row class="flex-nowrap justify-end">
                    <v-col align="right" class="flex-grow-0">
                        <v-row class="flex-nowrap justify-start">
                            <v-col class="flex-grow-0 pr-2">
                                <v-img :src="require('@/assets/CreateEditRealEstate/1.svg')" height="33" width="33" />
                            </v-col>
                            <v-col class="flex-grow-0">
                                <v-row>
                                    <v-col align="left" class="pt-2 pb-0 pl-0 textDecorationBold font14">
                                        <span> {{ $t("search2") }} </span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col align="left" class="pl-0 pt-0 textDecorationBold font14 text-no-wrap">
                                        <span>{{ data.Category }} </span>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col align="right" class="flex-grow-0">
                        <v-row class="flex-nowrap justify-start">
                            <v-col class="flex-grow-0 pr-2">
                                <v-img :src="require('@/assets/CreateEditRealEstate/2.svg')" height="32" width="32" />
                            </v-col>
                            <v-col class="flex-grow-0">
                                <v-row>
                                    <v-col align="left" class="pt-2 pb-0 pl-0 textDecorationBold font14">
                                        <span> {{$t("interesting")}} </span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col align="left" class="pl-0 pt-0 textDecorationBold font14 text-no-wrap">
                                        <span> {{data.Task}} </span>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                        </v-row>
                    </v-col>

                             <v-col class="flex-grow-0">
                        <v-row class="flex-nowrap justify-end">
                               <v-col align="left" class="flex-grow-0" v-if="canEdit && allowRoleEdit">
                        <v-btn class="error btn-custom" height="35px" width="120px" @click="editDemand">{{$t("edit")}}</v-btn>
                    </v-col>
                    <v-col align="left" class="flex-grow-0" v-if="canDelete && allowRoleEdit">
                        <v-btn outlined class="btn" height="35px" width="118px" @click="deleteDemand">{{$t("delete")}}</v-btn>
                    </v-col>
                        </v-row>
                    </v-col>

                 
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="px-2" v-if='!tabletBreakpoint'>
                <StepperField :steps='stepsArray' :selectedStep='selectedStep' :allowRoleEdit="allowRoleEdit" :doubleselectedStep="doubleselectedStep" :selectedStepChanged="selectedStepChanged" stepperWidth="1170px" :stepperCardWidth="stepsArray.length <= 6 ? '190px' : '167px'" />
            </v-col>
            <v-col v-else align="left">
                <v-select :items="stepsArray" return-object :value="selectedStep" item-value="id" item-text="description"  @change="selectedStepChanged" /> 
            </v-col>
        </v-row>
        <v-row v-if="loading || localLoading">
            <v-col class="py-0">
            <v-progress-linear indeterminate/>
            </v-col>
        </v-row>
        <v-row>
            <v-col v-if="data.inactivityDemand" class="pt-0" cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
                <HintField icon="mdi-alert-circle-outline" color="#E52628" class="textInter font13 hintBackground redAlert" backgroundColor="#fce4e5 " :data="$t('inactiveDemand')" hintFontSize="13" />
            </v-col>
        </v-row>
        <v-row>
            <v-col v-if="customerEvaluation" class="pt-0" cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
                <HintField icon="mdi-alert-circle-outline" hintFontSize="13" class="gunPowderColor font13 hintBackground fullWidth blueAlert" :data="$t('demandInformation')" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
                <v-row v-if="data.Estate">
                    <v-col align="left" class="font14 goldText textInterBold">
                        <span>{{$t("requestedRealEstate")}}</span>
                    </v-col>
                </v-row>
                <v-row class="mb-0" v-if="data.Estate">
                    <v-col>
                        <EstateInfoCard :image="data.Estate.MainPhoto ? data.Estate.MainPhoto : null" :characteristicsArray="characteristicsArray" 
                        :price="new Intl.NumberFormat('en-DE').format(data.Estate.Price) + ' ' + currency" :type="data.Estate.Task" :code="data.Estate.EstateCode" 
                        :title="data.Estate.Type" :address="data.Estate.Area" :id="data.Estate._id" 
                        :getSpecificEstate="openSpecificEstate" :supportSuggestionBtn="false" :supportCorspondBtn="false"/>
                    </v-col>
                </v-row>
                <v-row v-if="data.Price">
                    <v-col>
                        <EcomonicCriteria :data="data.Price" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="px-0">
                        <v-divider />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <EstateSpecifications :data="specificationsObject" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="px-0">
                        <v-divider />
                    </v-col>
                </v-row>
                <v-row>
                    <MapInfo :locations="mapLocationsObject" />
                </v-row>
                <v-row>
                    <v-col class="px-0">
                        <v-divider />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <DemandOrigin :data="demandOriginObject" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="px-0">
                        <v-divider />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <Details :data="detailsObject" :openContact="openContact" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="px-0">
                        <v-divider />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <ContactInfo :data="contactInfoObject" />
                    </v-col>
                </v-row>
            </v-col>
            <v-divider v-if="smAndDownBreakpoint != true" vertical></v-divider>
            <v-col>
                <!-- <v-row>
                    <v-col>
                        <ActionInfo :dataList="dataList" :hideDivider="true" />
                    </v-col>
                </v-row> -->
                <v-row>
                    <v-col>
                    <CommentsInfo
                        :requestId="data['_id']"
                        :data="comments"
                        :viewAllAccess="true"
                        :viewCommentsBtn="viewCommentsBtn"
                        @fetchDemands="fetchDemands"
                    />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row class="px-0 mt-0">
            <v-col cols="12" class="" align="left">
                <v-tabs :show-arrows="false" v-model="activeTab">
                    <v-tabs-slider color="error"> </v-tabs-slider>
                    <v-tab  v-if="data.Client" class="textTranformUnset textDecorationBold unsetCapitalize mr-1 cusTab">
                        {{ $t("valuationF") }}
                    </v-tab>
                    <v-tab class="textTranformUnset textDecorationBold unsetCapitalize mr-1 cusTab">
                        Matching Properties
                    </v-tab>
                    <!-- <v-tab class="
                textTranformUnset
                textDecorationBold
                unsetCapitalize
                mr-1
                cusTab
              ">
                        {{ $tc("offers") }}
                    </v-tab> -->
                </v-tabs>
            </v-col>
            <v-col class="px-0">
                <v-row>
                    <v-col class="py-0 mt-n3 px-0">
                        <v-divider />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-tabs-items v-model="activeTab">
                            <v-tab-item v-if="data.Client">
                                <ValuationDemands :userData="data.Client" :editHandler="editValuationHandler" :allowEvaluation="canEdit && allowRoleEdit"/>
                            </v-tab-item>
                            <v-tab-item>
                                <MatchingProperties  :dataId="data" :getSpecificEstate="openSpecificEstate" :type="getType" />
                            </v-tab-item>
                            <!-- <v-tab-item>
                                <OffersDemands />
                            </v-tab-item> -->
                        </v-tabs-items>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-dialog v-model="dialog.open" v-if="[2,5].includes(dialog.type)" max-width="752">
            <DemandOffer v-if="dialog.type == '5'" :cancelHandler="closeDialog" />
            <DemandAssignment v-if="dialog.type == '2'" :cancelHandler="closeDialog" />
        </v-dialog>

    </v-col>
</v-navigation-drawer>
</template>

<script>
/*
 Name:DemandSlider
 Description: Component for the demand slider. There is all the information about the demand in this slider.

 API Property:
    closeDemandSlider: Function to close the slider
    fetchContactById: Function to fetch the contact by id and open the contact slider
    getSpecificEstate: Function on get specific estate
    editValuationHandler: Function on edit valuation
    fetchDemands: Function on fetch Demands
    canEdit: Boolean if can edit
    onEditHandler: Function on edit
    canDelete: Boolean if can delete
    onDeleteHandler: Function on delete
    data: Object or Array to save the data for the demands
    loading: Boolean for loader
    allowEvaluation: Boolean if user can edit/create evaluation 
    viewCommentsBtn: Boolean for show comments save button


    Components Used:
        StepperField
        DemandAvatar
        HintField
        EstateInfoCard
        EcomonicCriteria
        EstateSpecifications
        MapInfo
        DemandOrigin
        Details
        ContactInfo
        CommentsInfo
        DemandOffer
        DemandAssignment
        ValuationDemands
        MatchingProperties
      
    Endpoints Functions Called:
      -RequestsDemandRepo
        update
*/

import StepperField from '@/components/Fields/StepperField/StepperField.vue';
import DemandAvatar from '@/components/Fields/DemandAvatar/DemandAvatar.vue';
import HintField from '@/components/Fields/HintField/HintField.vue';
import EstateInfoCard from '@/components/Demands/Slider/EstateInfoCard.vue';
import EcomonicCriteria from '@/components/Demands/Slider/EcomonicCriteria.vue';
import EstateSpecifications from '@/components/Demands/Slider/EstateSpecifications.vue';
import MapInfo from '@/components/Demands/Slider/MapInfo.vue';
import DemandOrigin from '@/components/Demands/Slider/DemandOrigin.vue';
import Details from '@/components/Demands/Slider/Details.vue';
import ContactInfo from '@/components/Demands/Slider/ContactInfo.vue';
import CommentsInfo from "@/components/Demands/Slider/CommentsInfo.vue";
//import ActionInfo from "@/components/Fields/ActionInfo/ActionInfo.vue"
import DemandOffer from "@/components/Demands/DemandOffer.vue";
import DemandAssignment from "@/components/Popups/DemandAssignment.vue";
import ValuationDemands from "@/components/Demands/Slider/ValuationDemands.vue";
import MatchingProperties from "@/components/Demands/Slider/MatchingProperties.vue";
//import OffersDemands from "@/components/Demands/Slider/OffersDemands.vue";
import { mapState } from "vuex";

import Repository from "@/services/Repository";
const RequestsDemandRepo = Repository.get("reqDemand");

import CoreHelper from "@/helpers/Core/core";
import DemandHelper from "@/helpers/Demand/demand.js";

import DemandsStaticLists from "@/static/demands/demands.js";

import i18n from "@/i18n/i18n";

export default {
    name: "DemandSlider",
    props: {
        closeDemandSlider: Function,
        fetchContactById: Function,
        getSpecificEstate: Function,
        editValuationHandler: Function,
        fetchDemands: Function,
        canEdit: {type: Boolean, default: false},
        onEditHandler: Function,
        canDelete: {type: Boolean, default: false},
        onDeleteHandler: Function,
        data: [Object, Array],
        loading: Boolean,
        allowEvaluation: { type: Boolean, default: true }, 
        viewCommentsBtn: { type: Boolean, default: true },

    },
    components: {
        StepperField,
        DemandAvatar,
        HintField,
        EstateInfoCard,
        EcomonicCriteria,
        EstateSpecifications,
        MapInfo,
        DemandOrigin,
        Details,
        ContactInfo,
        CommentsInfo,
        //ActionInfo,
        DemandOffer,
        DemandAssignment,
        ValuationDemands,
        MatchingProperties,
        //OffersDemands,
    },
    data() {
        return {
            displayAvatar: null,
            activeTab: 0,
            demandAvatarObj: {},
            steps: DemandsStaticLists.conditionList,
            iconObj: {
                icon: "mdi-eye",
                height: 15,
                color: "green"
            },
            show: true,
            characteristicsArray: [{
                    image: "realEstateSilder/bedroom.png",
                    value: 0,
                },
                {
                    image: "realEstateSilder/bathroom.png",
                    value: 0,
                },
                {
                    image: "realEstateSilder/sqft.png",
                    value: '',
                },
            ],
            comments: [],
            currency: null,
            dialog: {
                open: false,
                type: null,
                data: null,
            },
            localLoading: false
        };
    },
    computed: {
        ...mapState({
        userDetails: (state) => state.auth.userDetails,
        }),
        customerEvaluation() {
            return ![null,undefined,''].includes(this.data.Client) ? this.data.Client?.customer_evaluation ? false : true : false ;
        },
        mapLocationsObject() {
            return DemandHelper.initMapLocations(this.data);
        },
        demandOriginObject() {
            return DemandHelper.initDemandOrigin(this.data);
        },
        contactInfoObject() {
            return DemandHelper.initContactInfo(this.data);
        },
        detailsObject() {
            return DemandHelper.initDetailsSlider(this.data);
        },
        specificationsObject() {
            return DemandHelper.initSpecifications(this.data);
        },
        smAndDownBreakpoint() {
            return this.$vuetify.breakpoint.smAndDown;
        },
        mobileBreakpoint() {
            return this.$vuetify.breakpoint.xs;
        },
        tabletBreakpoint() {
            return this.$vuetify.breakpoint.width <= 800
        },   
        selectedStep() {
            try{
                return this.steps.find(o => o.val === this.data?.RequestStatus).id;
            } catch (err) {
                return 1
            }
        },
        stepsArray(){
            let steps = [];
            this.steps.map((m)=>{
                if (! ((this.data?.RequestStatus == 'withdrawal' && m.val == 'deal') 
                  || (this.data?.RequestStatus == 'deal' && m.val == 'withdrawal') || m.val == 'inert') )
                steps.push({id: m.id, description: i18n.t(m.text), val: m.val})
            })
            return steps;
        },
        getType(){
            return this.data?.EstateCode ? 'specific' : 'general';
        },
        allowRoleEdit(){
            // When the user must not edit the stepper from the estates opening 
            if(!this.viewCommentsBtn){
                return false;
            }
            if(this.userDetails.Role == "Admin"){
                return true;
            }else if(this.userDetails.Role == "Associate" || this.userDetails.Role == "Leader"){
                // If user associate and creator of the demand
               //Assigned
               if(this.userDetails._id == this.data.Agent?._id){
                 return true;
               }
               //Creator
               let creatorId = null;
               //If creator is object
                if(typeof(this.data?.Creator) == 'object'){
                    creatorId = this.data?.Creator?._id;
                } else if(this.data?.Creator){
                    creatorId = this.data?.Creator;
                }
                if(creatorId != null){
                    if(this.userDetails._id == creatorId){
                        return true;
                    }
                }
            } else if (this.userDetails.Role == "Leader") {
                var team = this.userDetails.Team && this.userDetails.Team.length > 0 ? this.userDetails.Team : [];
                if (team && team.length > 0) {
                    if (team.includes(this.data.Agent?._id)){
                    return true;
                    }
                }
            } else if(["Secretary", 'OfficeAdmin'].includes(this.userDetails.Role)){
                if(this.userDetails.LastCompany == this.data.LastCompany){
                    return true;
                } else {
                    return false;
                }
            }
            return false;
        }
    },
    watch: {
        data(){
            this.comments = this.data?.comments ? this.data.comments : this.comments;
        },
        show() {
            if (!this.show) {
                this.closeDemandSlider();
            }
        },
    },
    async created() {
        if ((this.data?.Agent == this.userDetails._id || this.data?.Agent?._id == this.userDetails._id) && (this.data?.unRead ?? true) == true){            
            this.localLoading = true;
            try{
                await RequestsDemandRepo.update({unRead: false}, this.data._id);
                this.fetchDemands();
                this.localLoading = false;
            }catch(err){
                console.error(err)
                this.localLoading = false;
            }
        }
        this.comments = this.data?.comments ? this.data.comments : this.comments;
        this.fetchDemandAvatar();
        this.currency = CoreHelper.getCurrency(true);
        if(this.data.EstateCode){
            this.fentchCharacteristicsArray();
        }
        
    },
    methods: {
        async openSpecificEstate(id) {
            this.localLoading = true;
            await this.getSpecificEstate(id);
            this.localLoading = false;
        },
        async openContact(id) {
            this.localLoading = true;
            await this.fetchContactById(id);
            this.localLoading = false;
        },
        editDemand(){
            this.onEditHandler(this.data)
        },
        deleteDemand(){
            this.onDeleteHandler(this.data)
        },
        fetchDemandAvatar(){
            let demandAvatar = this.data?.Agent ? 'Agent' : this.data?.Client ? 'Client' : this.data?.Creator ? 'Creator' : '';
            let firstname;
            let lastname;
            if(demandAvatar != null){
                this.demandAvatarObj["id"] = this.data[demandAvatar]?._id ? this.data[demandAvatar]?._id : this.data[demandAvatar];
                this.demandAvatarObj["data"] = this.getPhoto(this.data[demandAvatar]);
                this.demandAvatarObj["isImg"] = this.data[demandAvatar]?.Photo ? true : false ;
                firstname = this.data[demandAvatar]?.FirstName ? this.data[demandAvatar].FirstName : '-' ;
                lastname = this.data[demandAvatar]?.LastName ? this.data[demandAvatar].LastName : '-' ;
                this.demandAvatarObj["nameContent"] = firstname + '-' + lastname;
                this.demandAvatarObj["subContent"] =  this.data?.Agent ? '' : this.data?.Client?.customer_evaluation ? i18n.t("demandEvaluation") : '';
                this.demandAvatarObj["size"] = 50;
                this.demandAvatarObj["ratingData"] = this.data?.Client?.customer_evaluation?.avgRate ? this.data.Client.customer_evaluation.avgRate : '' ;
                this.demandAvatarObj["showRating"] = this.data[demandAvatar]?.ratingData ?  this.data[demandAvatar].ratingData > 1 ? true : false : false ;
                this.demandAvatarObj["nameTitleContent"] = this.getAvatarType(this.data[demandAvatar]);    
            }
        },
        getAvatarType(demandAvatar) {
        var result = null;
        if (demandAvatar.Role) {
            if (demandAvatar.Role == "Admin") {
            result = i18n.t("admin");
            } else if (demandAvatar.Role == "OfficeAdmin") {
            result = "Broker";
            } else if (demandAvatar.Role == "Secretary") {
            result = i18n.t("secretariat");
            } else if (demandAvatar.Role == "HR") {
            result = "HR Specialist";
            } else if (demandAvatar.Role == "Associate") {
            result = "Agent Pro";
            }
        } else {
            if(demandAvatar.Type){
            if (
            [
                "Πελάτης",
                "Πελάτης για Αγορά",
                "Πελάτης για Ενοικίαση",
                "Ενδιαφερόμενος για Αγορά",
                "Ενδιαφερόμενος για Ενοικίαση",
                "Ενδιαφερόμενος με Αντιπαροχή",
            ].some((ai) => demandAvatar.Type.includes(ai))
            ) {
            result = i18n.t("customer");
            } else if (["Member"].includes(demandAvatar.Type)) {
            result = "Member";
            } else if (["Υποψήφιος Συνεργάτης"].includes(demandAvatar.Type)) {
            result = i18n.t("associateCandidate");
            } else if (
            [
                "Ιδιοκτήτης για Πώληση",
                "Ιδιοκτήτης για Ενοικίαση",
                "Ιδιοκτήτης με Αντιπαροχή",
            ].some((ai) => demandAvatar.Type.includes(ai))
            ) {
            result = i18n.t("owner");
            }
        }

        return result;
        }
        return result;
        },
        getPhoto(obj) {
            return obj.Photo ? obj.Photo
                : obj.FirstName && obj.LastName ? obj.FirstName.charAt(0) + obj.LastName.charAt(0)
                    : obj.FirstName ? obj.FirstName.charAt(1)
                        : obj.LastName ? obj.LastName.charAt(1)
                            : ""
        },
        openDialog(type, content) {
            this.dialog = {
                open: true,
                type: type,
                content: content,
            };
        },
        closeDialog() {
            this.dialog = {
                open: false,
                type: null,
                content: null,
            };
        },
        doubleselectedStep(id) {
            this.openDialog(id);
        },
        async selectedStepChanged(data) {
            this.localLoading = true;
            try{
                await RequestsDemandRepo.update({RequestStatus: data.val}, this.data._id);
                this.fetchDemands();
                this.localLoading = false;
            }catch(err){
                console.error(err)
                this.localLoading = false;
            }
        },
        // isAuthorized(action, data) {
        //     let key = AuthHelper.getKeyForAuth(this.userDetails.Role);
        //     return AuthHelper.authorization(action, data[key] ? data[key] : null, data["userID"] ? data["userID"] : null);
        // },
        fentchCharacteristicsArray() {
            this.characteristicsArray[0].value = this.data.Estate?.characteristics?.bedrooms ? this.data.Estate.characteristics.bedrooms : "" ;
            this.characteristicsArray[1].value = this.data.Estate?.characteristics?.bathrooms ? this.data.Estate.characteristics.bathrooms : "" ;
            this.characteristicsArray[2].value = this.data.Estate?.Unit + ' ' + i18n.t("sqft3");
        },
    },
}
</script>

<style scoped>
.btn-custom {
    text-transform: capitalize !important;
    font-family: CenturyGothicBold !important;
    font-weight: 500 !important;
    font-size: 15px;
    background-color: white;
}

.btn {
    text-transform: unset !important;
    color: #263573 !important;
    background-color: white;
    font-weight: 500 !important;
    font-family: CenturyGothicBold !important;
}

.hintBackground {
    float: left !important;
    text-align: left !important;
    font-size: 13px !important;
}

.fullWidth {
    width: 100%;
}
</style>
